import { RouterProvider } from "react-router-dom";
import { router } from "./Router/Router";
import { useEffect } from "react";
import Customization from "./Components/Customization/Cutomization";
import useStore from "./Hooks/useStore";
import "./App.css";
import {saveInCookie} from "./Hooks/Helper";
const App = () => {
  const { setting } = useStore().data;
  const pixelCode = setting?.pixel_id
  window._DEFAULT_DATA = 'Data parsed in string'
  // console.log("sett", setting)

  useEffect(() => {
    // Set a global variable with the context value
    window.REACT_APP_CONTEXT_VALUE = pixelCode;
    localStorage.setItem("code", pixelCode)
    saveInCookie("code", pixelCode)
  }, [pixelCode]);

  useEffect(() => {
    window.onfocus = function () {
      document.title = setting?.name;
    };
    window.onblur = function () {
      document.title = "Come back soon";
    };
  }, [setting?.name]);

  return (
    <>
      {
        <div className="App bg-slate-50 dark:bg-gray-950">
          <Customization />
          <RouterProvider router={router} />
        </div>
      }
    </>
  );
};

export default App;
