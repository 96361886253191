import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css/navigation";
import "swiper/css/bundle";
// import { AiOutlineEye, AiOutlineHeart } from "react-icons/ai";
import { baseUrl } from "../../../Hooks/Helper";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
const ProductContainer5 = ({ data, flash }) => {
 
  return (
      <div className="container">
        <div className="grid grid-cols-12 gap-4 mt-5">
          <div className="lg:col-span-12 col-span-12 ">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1040: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              className="Popular"
            >
              {data?.slice(0, 8)?.map((product, i) => {
                const {
                  name,
                  thumbnail_image,
                  slug,
                  id,
                  display_price,
                  previous_display_price,
                  inventories_sum_stock_quantity,
                  product_review_rating,
                  is_on_sale,
                } = product;
                const discount =
                  ((previous_display_price - display_price) /
                    previous_display_price) *
                  100;
                const flashSalemoney = (display_price * flash?.discount) / 100;
                return (
                  <SwiperSlide key={i}>
                    <div className="border rounded dark:border-white p-3">
                      <div className="relative w-full  group">
                        <Link to={`/details/${id}/${slug}`}>
                          <div className="h-[300px]">
                            <img
                              src={
                                `${baseUrl.img}${thumbnail_image}` ||
                                "https://placehold.co/329x300"
                              }
                              className="w-full product-img-bg h-full group-hover:opacity-50 duration-300"
                              alt=""
                            />
                          </div>
                        </Link>
                      </div>

                      <div className="text-center pt-3">
                        <Link to={`/details/${id}/${slug}`}>
                          <h4 className="uppercase text-sm dark:text-white">
                            {name?.length > 15
                              ? name?.slice(0, 30) + "..."
                              : name}
                          </h4>
                        </Link>

                        <div className="text-yellow-800 flex justify-between items-center gap-x-2 pt-1">
                          <div>
                            {is_on_sale === 1 && flash ? (
                              <span>৳ {display_price - flashSalemoney}</span>
                            ) : (
                              <span>৳ {display_price}</span>
                            )}
                          </div>
                          {is_on_sale === 1 && flash ? (
                            <span>{flash?.discount.toFixed(0)}% OFF</span>
                          ) : previous_display_price ? (
                            <span>{discount.toFixed(0)}% OFF</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        {inventories_sum_stock_quantity > 0 ? (
                          <p className="text-green-500 font-normal text-xs text-start whitespace-nowrap">
                            In Stock
                          </p>
                        ) : (
                          <p className="text-red-500 font-normal text-xs text-start whitespace-nowrap">
                            Out of Stock
                          </p>
                        )}
                        <p className="mb-0 flex items-center">
                          {Array.from({ length: 5 }).map((_, i) => {
                            if (
                              i <
                              parseInt(
                                product_review_rating[0]?.avg_review_rating
                              )
                            ) {
                              return (
                                <AiFillStar
                                  key={i}
                                  className="text-orange-500"
                                />
                              );
                            }
                            return (
                              <AiOutlineStar
                                key={i}
                                className="text-gray-400"
                              />
                            );
                          })}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>

  );
};

export default ProductContainer5;
