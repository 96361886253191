import React, { useEffect } from "react";
import "./CartContainer.css";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartList,
  setUpdate,
} from "../../../Redux/Slices/Cart/cartListSlice";
import { BsArrowRight } from "react-icons/bs";
import { baseUrl, errorNotify, successNotify } from "../../../Hooks/Helper";
import useStore from "../../../Hooks/useStore";
import SectionHeading2 from "../../SectionHeadings/SectionHeading2/SectionHeading2";
import BreadCumb from "../../BreadCumb/BreadCumb";
import Loader2 from "../../Loaders/Loader2/Loader2";
import Button from "../../Button/Button";
import SectionWrapper from "../../SectionWrapper/SectionWrapper";
const CartContainer = () => {
  const { customerToken, secondaryToken, update } = useStore().data;
  const dispatch = useDispatch();
  const { cartItems, flash_sale, loading, updates } = useSelector(
    (state) => state?.cartItems
  );


  useEffect(() => {
    dispatch(fetchCartList({ customerToken, secondaryToken }));
  }, [dispatch, updates, customerToken, secondaryToken, update]);

  const navigate = useNavigate();
  let subTotal = 0;
  let shippingCharge = 0;
  let tax = 0;
  const updateQuantity = async (
    id,
    product_combination_id,
    quantity,
    type,
    product_inventory_qty
  ) => {
    const formData = {};
    formData.product_combination_id = product_combination_id;
    formData.user_unique_id = secondaryToken;
    if (type === "increment") {
      formData.quantity = +quantity + 1;
    } else {
      formData.quantity = +quantity - 1;
    }
    if (formData.quantity < 1) {
      errorNotify("You can't order less than one unit of our products");
      return;
    }
    if (formData.quantity > product_inventory_qty) {
      errorNotify("You can't order more than the available stock quantity");
      return;
    }
    if (customerToken && customerToken?.length > 20) {
      const res = await fetch(`${baseUrl.url}/user/cart-update/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${customerToken}`,
        },
        body: JSON.stringify(formData),
      });
      const resData = await res.json();
      if (resData.status === true) {
        dispatch(setUpdate());
        successNotify("Product quantity is updated successfully");
      } else {
        errorNotify(resData.message);
      }
    } else {
      const res = await fetch(`${baseUrl.url}/cart-update/${id}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: baseUrl.token,
        },
        body: JSON.stringify(formData),
      });
      const resData = await res.json();
      if (resData.status === true) {
        dispatch(setUpdate());
        successNotify("Product quantity is updated successfully");
      } else {
        errorNotify(
          resData?.errors?.map((err) => {
            return err;
          })
        );
      }
    }
  };
  const deleteCart = async (id) => {
    if (customerToken && customerToken?.length > 20) {
      const res = await fetch(`${baseUrl.url}/user/cart-delete/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          Authorization: `bearer ${customerToken}`,
        },
      });
      if (res.ok) {
        dispatch(setUpdate());
        successNotify("Your cart item is deleted successfully");
      }
    } else {
      const res = await fetch(`${baseUrl.url}/cart-delete/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "content-type": "application/json",
          Authorization: baseUrl.token,
        },
      });
      if (res.ok) {
        dispatch(setUpdate());
        successNotify("Your cart item is deleted successfully");
      }
    }
  };
  const goCheckout = () => {
    navigate("/checkout");
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const data = {
    heading: "My Cart",
    paragraph: null,
  };
  const navigationData = [
    {
      page: "Home",
      route: "/",
    },
    {
      page: "Cart",
      route: "/cart",
    },
    {
      page: "Cart items",
      route: "#",
    },
  ];

  if (loading && !cartItems?.length) {
    return <Loader2 />;
  }
  if (!loading && (cartItems?.length <= 0 || cartItems?.length === undefined)) {
    return (
        <SectionWrapper>
          <SectionHeading2 data={data}/>
          <BreadCumb data={navigationData}/>
          <div className="flex items-center justify-center mx-auto mt-3 ">
            <div>
              <h4 className="text-center text-base dark:text-white">
                Cart is empty
              </h4>
              <p className="text-xs text-center dark:text-white ">
                No items added in cart
              </p>
              <img
                  src="https://cdni.iconscout.com/illustration/premium/thumb/no-product-8316266-6632286.png"
                  alt=""
                  className="w-20 h-20  ms-3 mt-2"
              />
              <Link
                  to="/products"
                  className="mt-2 p-1 border hover:bg-orange-500 hover:text-white text-sm rounded bg-transparent flex items-center justify-center gap-1"
              >
                Goto Buy <BsArrowRight className="font-bold"/>
              </Link>
            </div>
          </div>
        </SectionWrapper>
    );
  }
  return (
      <section className="">
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        <div className="container">
          <div className="bg-white dark:bg-slate-800 p-4 my-3">
            <div className="grid grid-cols-12 gap-4">
              {!loading && cartItems?.length > 0 && (
                  <div className=" col-span-12 lg:col-span-8">
                    <SectionHeading2 data={data}/>
                    <BreadCumb data={navigationData}/>
                    <div
                        style={{minHeight: "53vh"}}
                        className="mt-6 overflow-scroll lg:overflow-hidden"
                >
                  <table className="w-full table-auto">
                    <thead className="w-full ">
                      <tr>
                        <th className="text-left">
                          <span className="text-sm lg:text-base dark:text-white text-center">
                            {" "}
                            Product
                          </span>
                        </th>
                        <th className="text-left">
                          <span className="text-sm lg:text-base dark:text-white text-center  ">
                            {" "}
                            Unit price
                          </span>
                        </th>
                        <th className="text-left">
                          <span className="text-sm lg:text-base dark:text-white text-center ml-2 lg:ml-0">
                            {" "}
                            Quantity
                          </span>
                        </th>
                        <th className="text-left">
                          <span className="text-sm lg:text-base dark:text-white text-center ml-2 lg:ml-0">
                            {" "}
                            Sub-total
                          </span>
                        </th>
                        <th className=""></th>
                      </tr>
                    </thead>
                    <tbody className="w-full">
                      {cartItems?.map((cart, i) => {
                        const isOnSale = cart?.product_combination?.product?.is_on_sale
                        const selling_price = cart?.product_combination?.selling_price
                        let price = isOnSale === 1 && flash_sale ?( selling_price - (selling_price * flash_sale?.discount) / 100) : cart?.product_combination?.selling_price;
                 
                          let total =cart?.product_quantity * price;
                        let product_inventory_qty =
                          cart?.product_combination
                            ?.inventory_sum_stock_quantity;
                         
                        return (
                          <tr key={i} className="w-full">
                            <td className="pl-2">
                              <div className="text-sm text-slate-700  flex-wrap lg:flex dark:text-white text-center">
                                <img
                                  className="w-12 h-12 rounded bg-slate-100 text-center"
                                  src={`${baseUrl.img}${cart?.product_combination.product?.thumbnail_image}`}
                                  alt="cart"
                                />
                                
                            <div>
                            <p className="text-sm lg:text-base lg:ml-2 text-gray-800">
                                  {cart?.product_combination?.product?.name?.slice(
                                    0,
                                    70
                                  )}
                                </p>
                                {cart?.product_combination?.attribute_values?.map(
                                (value, j) =>
                                  value?.name !== "default" && (
                                    <p
                                      key={j}
                                      className="text-xs font-bold dark:text-white text-left lg:ml-2"
                                    >
                                      {value?.attribute?.name}: {value?.name}
                                    </p>
                                  )
                              )}
                            </div>
                              </div>
                            
                            </td>
                            <td className="text-left pl-2 dark:text-white  ">
                              ৳ {price.toFixed(2)}
                            </td>
                            <td className=" ">
                              <div className="flex justify-start items-center px-2 lg:px-0">
                                <button
                                  className="text-center dark:text-white"
                                  onClick={() =>
                                    updateQuantity(
                                      cart?.id,
                                      cart?.product_combination?.id,

                                      cart?.product_quantity,

                                      "decrement",
                                      product_inventory_qty
                                    )
                                  }
                                >
                                  -
                                </button>
                                <button className="text-sm dark:text-white mx-4">
                                  {+cart?.product_quantity}
                                </button>
                                <button
                                  className="text-left  dark:text-white"
                                  onClick={() =>
                                    updateQuantity(
                                      cart?.id,
                                      cart?.product_combination?.id,

                                      cart?.product_quantity,

                                      "increment",
                                      product_inventory_qty
                                    )
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className=" pl-2 dark:text-white text-left">
                              ৳ {total.toFixed(2)}
                            </td>
                            <td className="text-center">
                              <RxCross1
                                className="cursor-pointer text-red-500  ml-5 lg:ml-0 "
                                onClick={() => deleteCart(cart?.id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {!loading && cartItems?.length > 0 && (
              <div className="col-span-12 lg:col-span-4">
                <div className="rounded w-[100%] lg:w-[85%] lg:ml-auto">
                  <div className="bg-white dark:bg-slate-800 rounded-lg mt-0 lg:mt-2 border overflow-hidden">
                    <div className="bg-gray-50 flex items-center justify-between p-2 border-b">
                      <h1 className="text-lg font-bold text-slate-700 dark:text-black">
                        Cart Items
                      </h1>
                      <p className="text-slate-500 text-sm dark:text-black">
                        {cartItems?.length} <span>{cartItems?.length===1?'item':'items'}</span> added
                      </p>
                    </div>
                    <div className="border-b item-details">
                      {/* <div className="my-4">
                        {cartItems?.length &&
                          cartItems?.map((cart, i) => {
                            let productPrice =
                              +cart?.product_quantity *
                              cart?.product_combination?.selling_price;
                            subTotal += productPrice;
                            const isOnSale = cart?.product_combination?.product?.is_on_sale
                            const selling_price = cart?.product_combination?.selling_price
                            let price = isOnSale === 1 && flash_sale ?( selling_price - (selling_price * flash_sale?.discount) / 100) : cart?.product_combination?.selling_price;
                            return (
                              <div
                                key={i}
                                className="flex items-start justify-between gap-2 mb-1 p-2 "
                              >
                                <div>
                                  <span className="block font-bold text-base text-gray-800 dark:text-white">
                                    {i + 1}.{" "}
                                    {cart?.product_combination?.product?.name}
                                  </span>
                                  {cart?.product_combination?.attribute_values?.map(
                                (value, j) =>
                                  value?.name !== "default" && (
                                    <p
                                      key={j}
                                      className="text-xs font-bold dark:text-white text-left text-gray-700"
                                    >
                                      {value?.attribute?.name}: {value?.name}
                                    </p>
                                  )
                              )}
                                </div>
                                <div className="flex items-center">
                                  <p className="dark:text-white">৳</p>
                                  <p className="font-normal dark:text-white">
                                    { (price *(+cart?.product_quantity)).toFixed(0)}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div> */}

<div className="my-4">
  {cartItems?.length &&
    cartItems?.map((cart, i) => {
      const isOnSale = cart?.product_combination?.product?.is_on_sale;
      const selling_price = cart?.product_combination?.selling_price;
      const quantity = +cart?.product_quantity;
      const discount = flash_sale?.discount;
      const flashSaleActive = isOnSale === 1 && flash_sale;

      let price = flashSaleActive
        ? selling_price - (selling_price * discount) / 100
        : selling_price;

      // Calculate the individual product total
      const productTotal = price * quantity;

      // Add the individual product total to the subTotal
      subTotal += productTotal;

      return (
        <div
          key={i}
          className="flex items-start justify-between gap-2 mb-1 p-2"
        >
          <div>
            <span className="block font-bold text-base text-gray-800 dark:text-white">
              {i + 1}. {cart?.product_combination?.product?.name}
            </span>
            {cart?.product_combination?.attribute_values?.map(
              (value, j) =>
                value?.name !== "default" && (
                  <p
                    key={j}
                    className="text-xs font-bold dark:text-white text-left text-gray-700"
                  >
                    {value?.attribute?.name}: {value?.name}
                  </p>
                )
            )}
          </div>
          <div className="flex items-center gap-1">
            <p className="dark:text-white">৳</p>
            <p className="font-normal dark:text-white">
              {productTotal.toFixed(2)}
            </p>
          </div>
        </div>
      );
    })}
</div>

                    </div>
                    <div className="p-3 border-b my-6">
                      <div className="flex items-center justify-between">
                        <span className="dark:text-white">Subtotal</span>
                        <span className="font-bold dark:text-white">
                          ৳ {subTotal.toFixed(2)}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="dark:text-white">Shipping</span>
                        <span className="font-bold dark:text-white">
                          ৳ {shippingCharge}
                        </span>
                      </div>
                      <div className="flex items-center justify-between">
                        <span className="dark:text-white">Tax</span>
                        <span className="font-bold dark:text-white">
                          ৳ {tax}
                        </span>
                      </div>
                    </div>
                    <div className="p-3">
                      <div className="flex items-center justify-between">
                        <span className="font-bold dark:text-white ">
                          Total
                        </span>
                        <span className="font-bold dark:text-white">
                          ৳ {(subTotal + tax + shippingCharge).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="p-3">
                      <p className="text-sm text-gray-500 dark:text-white">
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our privacy policy.
                      </p>
                    </div>
                    <div className="p-3">
                      {/* <button
                        className="w-full rounded bg-yellow-500 p-2"
                        onClick={goCheckout}
                      >
                        Checkout
                      </button> */}
                      <Button size={"md"} classes={"w-full "} func={goCheckout} title={"Checkout"}/>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CartContainer;
